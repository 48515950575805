import { createContext, useContext, useEffect, useState } from 'react'
import {  onAuthStateChanged } from "firebase/auth";
import { artistAuth } from '../../firebase';

const AuthContext = createContext();

export function useAuth(){
    return useContext(AuthContext)
}

export function AuthProvider( {children} ){

    const [artistUser, setArtistUser] = useState(null)
    const [isArtistLoading, setArtistIsLoading] = useState(true)

  useEffect(() => {
   
    let unsubscribe= onAuthStateChanged(artistAuth, (artist) => {
        if (artist) {
            console.log(artist)
            setArtistUser(artist)
            setArtistIsLoading(false)
        } else {
            setArtistUser(null)
            setArtistIsLoading(false)
        }
      });

    return unsubscribe;
  }, []);


    const value = {
          artistUser
    }
    
    return (
        <AuthContext.Provider value={value}>
            {!isArtistLoading && children}
        </AuthContext.Provider>

    )
}

