import React, { useState, useEffect} from 'react'
import './ArtistDashboard.css'
import axios from 'axios'
import { toast} from 'react-toastify'

export default function ActContainer({act, i}) {

    useEffect(()=>{
        getRosters()
    },[])

    const [rosters, setRosters] = useState([])
    const [events, setEvents] = useState([])


    const getRosters = () =>{
        if(act.rosters.length > 0){

            act.rosters.map((id)=>{
                axios.post(`${process.env.REACT_APP_API}/acts/get-roster`, {_id:id})
                .then((res)=>{
                    setRosters([...rosters, res.data])
                    getGigs(id)
                })
                .catch(()=>toast.error("Oops, something went wrong"))
            })
            
        }
    }

    const getGigs = (id) =>{
        axios.post(`${process.env.REACT_APP_API}/event/get-gigs-by-roster`, {rosterId:id})
                .then((res)=>{setEvents([...events, ...res.data])})
                .catch(()=>toast.error("Oops, something went wrong"))
    }

  return (
    <div className='act-container' key={i}>
            <img className='act-img' src={act.img} alt={act.actName}/>
            <div>
              <h3>{act.actName} - {events.length} gigs available</h3>
              <p>Category - {act.category}</p>
              <h4>Rosters</h4>
              
              <div style={{display: 'flex', flexDirection: 'row', flexWrap:'wrap'}}>
                    {rosters.length < 1 ?
                            <p>No Rosters added for this act</p>:
                    rosters.map((roster, i)=>{
                        return (
                            <div key={i} className='act-roster'>
                                <img className='venue-roster-img' src={roster.venueLogo} />
                                <p className='act-roster-info'> {roster.venueName}</p>
                                <p className='act-roster-info'>({roster.rosterName})</p>
                            </div>
                        )
                        
                    })}

              </div>
            {console.log(events)}
            </div>
            
          </div>
  )
}
