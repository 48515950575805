import React, { useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Auth.css'
import { createUserWithEmailAndPassword } from "firebase/auth";
import { artistAuth } from '../../firebase';
import axios from 'axios'
import { toast } from 'react-toastify'

export default function ArtistSignUp() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirm, setConfirm] = useState("")

  let navigate = useNavigate()

  const handleSubmit = (e) =>{
    e.preventDefault();

    if(!name || !email || !password){
      toast.error("Please fill in all fields")
    } else if(password !== confirm){
      toast.error("Passwords do not match")
    } else {
      createUserWithEmailAndPassword(artistAuth, email, password)
      .then((userCredential) => {
        const payload = {
          _id: userCredential.user.uid, name, email, address:{lineOne:"",lineTwo:"", town:"", county:"", postcode:""}, ni: ""
        }
       axios.post(`${process.env.REACT_APP_API}/artist/create-artist`, payload)
       .then((res)=>{
         toast.success("Account successfully created")
          navigate('/')})
       .catch((e)=>toast.error("Oops, something went wrong"))
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage)
      });
    }

  }

  return (
    <main id='signup-page'>
            <img className='auth-logo' src={require('../../images/logo-white.png')} alt='Music Gofer Logo'/>

    <form className='login-form'>
      <h1 className='auth-page-title'>Artist Sign Up</h1>
      <input className='login-input' type='text' value={name} onChange={(e)=>setName(e.target.value)} placeholder="Artist Name" />
      <input className='login-input' type='text' value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Email address" />
      <input className='login-input' type='password' value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="Password"/>
      <input className='login-input' type='password' value={confirm} onChange={(e)=>setConfirm(e.target.value)} placeholder="Confirm Password"/>

      <button type='submit' className='auth-btn' onClick={handleSubmit}>Create Free Account</button>
      <p className='info'>Already got an account? <Link to="/login" style={{color:'#007cd2'}}>Sign In</Link></p>
    </form>

  </main>
  )
}
