import React, {useState, useEffect} from 'react'
import './ArtistRoster.css'
import axios from 'axios'
import { useAuth } from '../auth/AuthContext'

export default function ArtistRoster() {
    const [currentRosters, setCurrentRosters] = useState([])

    const { artistUser } = useAuth()

    useEffect(()=>{
      getRosters()
    },[])

    const getRosters = () =>{
      axios.post(`${process.env.REACT_APP_API}/artist/get-roster-by-id`, {_id: artistUser.uid})
      .then((res)=>{
        console.log(res.data)
      })
      .catch((e)=>console.log(e))
    }

  return (
    <main>
        <h3 style={{margin:'1rem'}} >Current Rosters</h3>
        <div className='artist-roster-container'>
            {currentRosters.length < 1 && <p>No current rosters</p>}
        </div>

    
    </main>
  )
}
