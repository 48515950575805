import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseArtistConfig = {
    apiKey: "AIzaSyBFbfe-_ieaEKYrkKLR_0OOAGkivKYsyL8",
    authDomain: "artistauth.firebaseapp.com",
    projectId: "artistauth",
    storageBucket: "artistauth.appspot.com",
    messagingSenderId: "505872799059",
    appId: "1:505872799059:web:e545cefe84da2684137da6"
  };

// Initialize Firebase
const artistApp = initializeApp(firebaseArtistConfig, "artist");

export const artistAuth = getAuth(artistApp);
export const storage = getStorage(artistApp);
