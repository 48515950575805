import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ArtistAuth = () => {

    const {artistUser} = useAuth()
    console.log(artistUser)
    let location = useLocation();

    return artistUser  ? <Outlet /> : <Navigate to="/login" state={{from : location}} replace/> 

};

export default ArtistAuth;