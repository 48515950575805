import React, { useState} from 'react'
import './Auth.css'
import { Link, useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword } from "firebase/auth";
import { artistAuth } from '../../firebase'

export default function ArtistLogin() {

  let navigate = useNavigate();

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const handleSubmit = (e) =>{
    e.preventDefault();

    if(!email || !password){
      alert("Please add all fields")
    } else {
      signInWithEmailAndPassword(artistAuth, email, password)
      .then((userCredential) => {
        console.log("Artist signed in")
        navigate('/')
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage)
      });
    }
  }

  return (
    <main id='login-page'>

      <img className='auth-logo' src={require('../../images/logo-white.png')} alt='Music Gofer Logo'/>
      <form className='login-form'>
        <h1 className='auth-page-title'>Artist Login</h1>
        <input className='login-input' type='text' value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Email Address' />
        <input className='login-input' type='password' value={password} onChange={(e)=>setPassword(e.target.value)} placeholder='Password'/>
        <button onClick={handleSubmit} className='auth-btn'>Log in</button>
        <p className='info'>Haven't got an account? <Link to="/signup" style={{color:'#007cd2'}}>Register for free</Link></p>

      </form>

    </main>
  )
}
