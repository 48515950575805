import React, { useState} from 'react'
import { Outlet, Link } from 'react-router-dom'
import { artistAuth } from './firebase'
import { signOut } from "firebase/auth";
import './ArtistUI.css'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import RecentActorsIcon from '@mui/icons-material/RecentActors';

export default function ArtistUI() {

  const [open, setOpen] = useState(true)

  const toggleOpen =()=> {
    setOpen(!open)
  }

  const handleLogout = () =>{
    signOut(artistAuth).then(() => {
      console.log("signed out")
    }).catch((error) => {
      console.log(error)
    });
  }

  return (
    <div>
        
        <nav className={open? 'artist-nav-open' : 'artist-nav-closed'}>
          <div style={{width: '100%', textAlign:'center'}}>
            <img id='nav-logo' src='https://firebasestorage.googleapis.com/v0/b/venueauth.appspot.com/o/logo-white.png?alt=media&token=41ff9b00-66ec-48b2-a6a1-37502db5bb6a' alt='Music Gofer Logo'/>
            
            <Link to="/" className='nav-item'>
              <LibraryMusicIcon sx={{color: '#fff'}}/>
              <p className='nav-title'>Manage Acts</p>
            </Link>

            <Link to="roster" className='nav-item'>
              <RecentActorsIcon sx={{color: '#fff'}}/>
              <p className='nav-title'>Rosters</p>            
            </Link>

            <Link to="calendar" className='nav-item'>
              <CalendarMonthIcon sx={{color: '#fff'}}/>
              <p className='nav-title'>Calendar</p>
            </Link>

            <Link to="settings" className='nav-item'>
              <SettingsIcon sx={{color: '#fff'}}/>
              <p className='nav-title'>Settings</p>
            </Link>
          </div>
         
          <button onClick={handleLogout} id='logout-btn'>Logout</button>
        </nav>
          
      <div id={open ? 'main-open': 'main-closed'}>
        <Outlet />
      </div>
    </div>
  )
}
