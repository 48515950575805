import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Components
import ArtistUI from './ArtistUI';
import ArtistSignUp from './components/auth/ArtistSignUp';
import ArtistLogin from './components/auth/ArtistLogin';
import ArtistAuth from './components/auth/ArtistAuth';
import ArtistDashboard from './components/artists/ArtistDashboard'
import { AuthProvider } from "./components/auth/AuthContext";
import ArtistRoster from './components/artists/ArtistRoster';
import ArtistCalendar from './components/artists/ArtistCalendar';
import ArtistSettings from './components/artists/ArtistSettings';
import CreateAct from './components/artists/CreateAct';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <ToastContainer position='top-center'/>
    <BrowserRouter>
      <Routes>
        {/*Unauthenticated routes*/}
          <Route path="/signup" element={< ArtistSignUp/>} />
          <Route path="/login" element={< ArtistLogin/>} />

        {/*Artist authenticated routes*/}
        <Route   element={<ArtistAuth  />} >
          <Route path="/" element={<ArtistUI />}>
            <Route index element={< ArtistDashboard/>} />
            <Route path="create-act" element={<CreateAct />}/>
            <Route path="roster" element={<ArtistRoster />}/>
            <Route path="calendar" element={<ArtistCalendar />}/>
            <Route path="settings" element={<ArtistSettings />}/>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </AuthProvider>,
);


