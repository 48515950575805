import React, { useState} from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useAuth } from '../auth/AuthContext'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useNavigate} from 'react-router-dom'
import './CreateAct.css'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../../firebase'
import { v4 as uuidv4 } from 'uuid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function CreateAct() {
    const [name, setName] = useState("")
    const [category, setCategory] = useState("")
    const [img, setImg] = useState("https://firebasestorage.googleapis.com/v0/b/artistauth.appspot.com/o/image-placeholder-350x350-1.png?alt=media&token=d4750728-c4cb-4bff-bede-69e5f5c13e77")
    const [video, setVideo] = useState("")
    const [bio, setBio] = useState("")
    const [location, setLocation] = useState("")

    const { artistUser } = useAuth();

    let navigate = useNavigate()


    const payload = {
      artistId: artistUser.uid, actName: name, bio, location, category, subCategory:"", img, video, setList:[], rosters: []
    }

    const saveAct = () =>{
      if(!name || !category || !img || !video || !location){
        toast.error("Please add a name, category, image and video and location")
      } else {
        axios.post(`${process.env.REACT_APP_API}/artist/create-act`, payload)
        .then(()=> {
          navigate('/')
          toast.success("Successfuly created")
        })
        .catch(()=>toast.error("Oops, something went wrong"))
      }
    }
    const storageRef = ref(storage, `${artistUser.uid}/${name}/${uuidv4()}`);

    const uploadImg = (file) =>{
      uploadBytes(storageRef, file).then((snapshot) => {

          getDownloadURL(storageRef)
          .then((url) => {
              setImg(url)
          })
          .catch((error) => {
              console.log(error)
          });
      });
  }



  return (
    <main >
        <div id='create-act-container'>
            <img id='act-img' src={img} alt={name}/>
            <input type='file' onChange={(e)=>uploadImg(e.target.files[0])}/>
            <TextField sx={{my:1}} fullWidth variant='outlined' label='Act Name' value={name} onChange={(e)=>setName(e.target.value)} />

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Category"
                onChange={(e)=>setCategory(e.target.value)}
              >
                <MenuItem value={""}>Select Category</MenuItem>
                <MenuItem value={"Mellow"}>Mellow</MenuItem>
                <MenuItem value={"Lively"}>Lively</MenuItem>
                <MenuItem value={"Tribute"}>Tribute</MenuItem>
                <MenuItem value={"Band"}>Band</MenuItem>
                <MenuItem value={"Duo"}>Duo</MenuItem>

              </Select>
            </FormControl>

            <TextField sx={{my:1}} fullWidth variant='outlined' label='Video' value={video} onChange={(e)=>setVideo(e.target.value)} />

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Location</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={location}
                label="Location"
                onChange={(e)=>setLocation(e.target.value)}
              >
                <MenuItem value={""}>Select Location</MenuItem>
                <MenuItem value={"North West"}>North West</MenuItem>
                <MenuItem value={"North East"}>North East</MenuItem>
                <MenuItem value={"East Midlands"}>East Midlands</MenuItem>
                <MenuItem value={"West Midlands"}>West Midlands</MenuItem>
                <MenuItem value={"East of England"}>East of England</MenuItem>
                <MenuItem value={"London"}>London</MenuItem>
                <MenuItem value={"South East"}>South East</MenuItem>
                <MenuItem value={"South West"}>South West</MenuItem>
              </Select>
            </FormControl>

            <TextField sx={{my:1}} multiline rows={3} fullWidth variant='outlined' label='Bio' value={bio} onChange={(e)=>setBio(e.target.value)} />


            <Button sx={{my: 1, width: 200}} variant='contained' onClick={saveAct}>Create</Button>
        </div>

    </main>
  )
}
