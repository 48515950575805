import React, {useState, useEffect} from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import axios from 'axios'
import { useAuth } from '../auth/AuthContext'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from 'react-toastify'
export default function ArtistCalendar() {

  const [events, setEvents] = useState([])
  const { artistUser } = useAuth();
  const [actId, setActId] = useState("")
  const [acts, setActs] = useState([])
  const [act, setAct] = useState("")

  useEffect(()=>{
    getEvents()
    getActs()

  },[])

  useEffect(()=>{
    if(actId){
      setEvents([])
      getInfo();
    }
  },[actId])

  useEffect(()=>{
    if(act){
      getGigs()
    }
  },[act])

  const getInfo = () =>{
    let res = acts.filter((act=> act._id == actId))
    setAct(res[0])
  }


  const getGigs = () =>{
    if(act.rosters.length > 0){

        act.rosters.map((id)=>{
          axios.post(`${process.env.REACT_APP_API}/event/get-gigs-by-roster`, {rosterId:id})
          .then((res)=>{setEvents([...events, ...res.data])})
          .catch(()=>toast.error("Oops, something went wrong"))
        })
        
    }
}


  const getActs = () =>{
    axios.post(`${process.env.REACT_APP_API}/artist/get-acts`, {artistId: artistUser.uid})
    .then((res)=>{setActs(res.data)})
    .catch(()=>toast.error("Oops, something went wrong"))
  }


  const getEvents = () =>{
    axios.post(`${process.env.REACT_APP_API}/artist/get-events-by-id`, {_id: artistUser.uid})
    .then((res)=>{
      console.log(res.data)
    })
    .catch((e)=>console.log(e))
  }

  return (
    <div style={{padding: '1rem'}}>
      <FormControl fullWidth sx={{mb:2}}>
        <InputLabel id="demo-simple-select-label">Act</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={actId}
          label="Act"
          onChange={(e)=>setActId(e.target.value)}
        >
          <MenuItem value="">Select Act</MenuItem>
         {acts.length > 0 && acts.map((act,i)=>{
           return <MenuItem value={act._id}>{act.actName} ({act.category})</MenuItem>

         })}
        </Select>
      </FormControl>

      <FullCalendar
        plugins={[ dayGridPlugin ]}
        initialView="dayGridMonth"
        events={events}
        eventDidMount={function(info) {
          console.log(info )
        
        } 
      }
      />
    </div>
  )
}
