import React, { useState, useEffect} from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import './ArtistSettings.css'
import { useAuth } from '../auth/AuthContext'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function ArtistSettings() {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [lineOne, setLineOne] = useState("")
    const [lineTwo, setLineTwo] = useState("")
    const [town, setTown] = useState("")
    const [county, setCounty] = useState("")
    const [postcode, setPostcode] = useState("")
    const [ni, setNi] = useState("")

    const {artistUser} = useAuth();

    useEffect(()=>{
      getInfo()
    },[])

    const getInfo = () =>{
      axios.post(`${process.env.REACT_APP_API}/artist/get-artist-by-id`, {_id: artistUser.uid})
      .then((res)=>{
        const {name, email, address, ni} = res.data
        setName(name); setEmail(email); setNi(ni); 
        setLineOne(address.lineOne); setLineTwo(address.lineTwo); setTown(address.town); setCounty(address.county); setPostcode(address.postcode)
      })
      .catch((e)=>console.log(e))
    }

    const handleUpdate = () =>{

      if(!name || !email || !ni || !lineOne || !postcode){
        toast.error("Please add all fields")
      } else {
        axios.post(`${process.env.REACT_APP_API}/artist/update-artist`,{ _id:artistUser.uid, payload:{
          name, email, address:{lineOne, lineTwo, town, county, postcode}, ni}
        })
        .then(()=>toast.success("Successfully updated"))
        .catch(()=>toast.error("Oops, something went wrong"))
      }

    }

  return (
    <main>
        <div id='artist-settings'>
            <TextField sx={{my:1}} variant='outlined' fullWidth label='Full Name' value={name} onChange={(e)=>setName(e.target.value)}/>
            <TextField sx={{my:1}} variant='outlined' fullWidth label='Email' disabled value={email}/>
            <TextField sx={{my:1}} variant='outlined' fullWidth label='Address Line 1*' value={lineOne} onChange={(e)=>{setLineOne(e.target.value)}}/>
            <TextField sx={{my:1}} variant='outlined' fullWidth label='Address Line 2' value={lineTwo} onChange={(e)=>{setLineTwo(e.target.value)}}/>
            <TextField sx={{my:1}} variant='outlined' fullWidth label='Town' value={town} onChange={(e)=>{setTown(e.target.value)}}/>
            <TextField sx={{my:1}} variant='outlined' fullWidth label='County' value={county} onChange={(e)=>{setCounty(e.target.value)}}/>
            <TextField sx={{my:1}} variant='outlined' fullWidth label='Postcode*' value={postcode} onChange={(e)=>{setPostcode(e.target.value)}}/>
            <TextField sx={{my:1}} variant='outlined' fullWidth label='National Insurance*' value={ni} onChange={(e)=>setNi(e.target.value)}/>

            <Button variant='contained' onClick={handleUpdate}>Update</Button>
        </div>

    </main>
  )
}
