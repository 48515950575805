import React, { useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import './ArtistDashboard.css'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../auth/AuthContext'
import ActContainer from './ActContainer'

export default function ArtistDashboard() {

  const [acts, setActs] = useState([])
  const { artistUser} = useAuth();

  useEffect(()=>{
    getActs()
  },[])

  const getActs = () =>{
    axios.post(`${process.env.REACT_APP_API}/artist/get-acts`, {artistId: artistUser.uid})
    .then((res)=>{setActs(res.data)})
    .catch(()=>toast.error("Oops, something went wrong"))
  }

  return (
    <main >
      <Link to={'create-act'}><button id='create-act-btn'>Create New Act</button></Link>

      {acts.length < 1 ? <p>No Acts Added</p> :
      acts.map((act, i)=>{
        return (
          <ActContainer act={act} i={i} />
        )
      })
      } 

    </main>
  )
}
